import * as React from "react";
import { Layout } from "../../components/layout";

const IndexPage = () => {
  return (
    <>
      <Layout title="Aufzucht">
        <main className="container">
          <h1>Aufzucht</h1>
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
